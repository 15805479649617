@import url(https://fonts.googleapis.com/css?family=Roboto:regular);
#searchTitle {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500px;
}

.SearchUI {
	color: whitesmoke;
	font-family: "Helvetica";
	font-weight: 400;
	font-size: 22px;
}

#searchBar {
	width: 20%;
	height: 30px;
}

#prefixMenu {
	height: 35px;
	padding: 1px 2px;
}

#submitBtn {
	width: 133px;
	height: 30px;
	background-color: white;
	color: black;
	border: none;
	border-radius: 10px;
	font-family: "Roboto";
	font-size: large;
	margin-top: 20px;
}
.css-fix {
	white-space: pre-wrap; /* or pre-line */
}

@media only screen and (max-width: 600px) {
	#searchBar {
		width: 60%;
	}
}
