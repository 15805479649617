/*
Styling for everything on the homepage
*/

@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:regular); /*Roboto Slab*/
@import url(https://fonts.googleapis.com/css?family=Roboto:regular);

:root {
	--highlight-red: #f50c0c;
}

#searchTitle {
	font-family: "Roboto Mono", "Times New Roman", Times, serif;
	font-size: 18px;
}

.SearchUI {
	color: whitesmoke;
	font-family: "Helvetica";
	font-weight: 400;
	font-size: 22px;
}

p {
	color: whitesmoke;
	font-family: "Roboto Slab", "Times New Roman", Times, serif;
}

#searchBar {
	width: 20%;
	height: 30px;
}
#prefixMenu {
	height: 30px;
	margin-right: 2px;
}

#submitBtn {
	width: 133px;
	height: 30px;
	background-color: white;
	color: black;
	border: none;
	border-radius: 10px;
	font-family: "Roboto";
	font-size: large;
	margin-top: 20px;
}

.whiteLink:visited {
	color: white;
}

#queriedKeikka {
	color: var(--highlight-red);
}

.inline {
	display: inline-block;
}

#keikkaDesc {
	white-space: pre-line;
	vertical-align: bottom;
}

@media only screen and (max-width: 800px) {
	#searchTitle {
		font-size: 24px;
	}
	.SearchUI {
		font-size: 15px;
	}
	#searchBar {
		width: 50%;
		height: 30px;
	}
}
