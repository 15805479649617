/*
Global Styles. Only globally used components should be styled here
*/
:root {
	--highlight-red: #f50c0c;
}

@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:regular); /*Roboto Mono*/
@import url(https://fonts.googleapis.com/css?family=Roboto+Serif:regular); /* Roboto Serif` */
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:regular); /*Roboto Slab*/

body {
	background-color: #272727;
	color: white;
	text-align: center;
	min-height: 100%;
	display: grid;
	grid-template-rows: 1fr auto;
	overflow-x: hidden;
}

.selectView button {
	margin-top: 10px;
}

.panel button {
	margin-top: 10px;
}

.container {
	display: flex;
	flex-direction: column;
	min-height: 100vh; /* Full viewport height */
}

.contentWrap {
	flex: 1;
}
.fallback {
	text-align: center;
	justify-self: center;
}

/* All Footer Components */

footer {
	width: 100%;
}

#abtLink {
	color: whitesmoke;
	font-family: "Roboto Slab";
	text-decoration: underline;
	text-decoration-color: var(--highlight-red);
}

/* Header */

#header {
	color: whitesmoke;
	font-family: "Roboto Serif";
	font-size: 40px;
}

#headerAnchor {
	color: whitesmoke;
	text-decoration: none;
}

#headerAnchor:hover {
	text-decoration: underline;
}

.androidAd {
	width: 100%;
	overflow-x: hidden;
	background-color: rgb(207, 6, 6, 1);
}

#androidInstallLink {
	color: white;
	:hover {
		color: white;
	}
}

.inline {
	display: inline-block;
	margin: auto 10px auto 10px;
}

.quickLink {
	text-decoration: underline solid white;
}
